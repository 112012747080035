


































import { Component, Vue } from 'vue-property-decorator'
import EmailInputRenderer from '@/components/facts/renderers/EmailInputRenderer.vue'
import { Action, Getter } from 'vuex-class'
import { FactChangeEvent } from '@/interfaces/FactChangeEvent'
import { EmailChange } from '@/services/EmailChangeService'
import constants from '@/constants'
import { FactValidationEvent } from '@/interfaces/FactValidationEvent'
import { ACTION_SET_NEW_EMAIL } from '@/store/modules/user/actionTypes'
import BackDashboardButton from '@/components/BackDashboardButton.vue'

@Component({
  components: {
    BackDashboardButton,
    EmailInputRenderer
  }
})
export default class UpdateMyInfo extends Vue {
  @Getter('user/email') email!: string
  @Getter('user/newEmail') newEmail!: string
  @Action(ACTION_SET_NEW_EMAIL) setNewEmail!: (email: string) => void

  private pageValid = false
  private clicked = false

  // Model for current email input
  private currentEmailFact = {
    name: this.$t('updateMyInfo.field.currentEmail'),
    currentValue: this.email,
    readonly: true
  }

  // Model for new email input
  private newEmailFact = {
    id: 'newEmail',
    name: this.$t('updateMyInfo.field.enterNewEmail'),
    currentValue: this.newEmail
  }

  // Email is valid if it is different and an actual email
  onEmailChange(evt: FactChangeEvent) {
    this.newEmailFact.currentValue = evt.value?.trim()?.toLocaleLowerCase()
    if (this.newEmailFact.currentValue === this.currentEmailFact.currentValue) {
      this.pageValid = false
    }
  }

  // Make sure email shows up in UI
  mounted() {
    this.currentEmailFact.currentValue = this.email
    this.newEmailFact.currentValue = this.newEmail

    this.pageValid = this.newEmailFact.currentValue !== '' &&
                     this.newEmailFact.currentValue !== this.currentEmailFact.currentValue
  }

  onFactValidation(evt: FactValidationEvent) {
    this.pageValid = evt.valid
  }

  // Proceed to OTP input
  async updateEmail() {
    this.clicked = true
    try {
      const newEmail = this.newEmailFact.currentValue

      await EmailChange.startVerifyEmailFlow(newEmail)
      this.setNewEmail(newEmail)
    } catch (e) {
      this.clicked = false
      console.error(e)
    }

    await this.$router.push({ name: constants.routeNames.UPDATE_MY_INFO_VERIFY })
  }

  displayErrorMessage() {
    return !this.pageValid && this.newEmailFact.currentValue?.length > 0
  }
}
