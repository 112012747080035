import { otpXhr, keepOnErrorAxios } from '../Auth'
import { Config } from '@/config'
import { ApiFactory } from '@uncharted/coverhub-framework'
import {
  V1AccountApi,
  V1AccountApiInterface
} from 'shift-account-service-api-client'

// Client for accessing accounts api
export const Accounts = ApiFactory<V1AccountApiInterface, V1AccountApi>(
  V1AccountApi,
  Config.shift.url,
  otpXhr
)

// Client for accessing accounts api without clean token on error response.
export const KeepOnErrorAccounts = ApiFactory<V1AccountApiInterface, V1AccountApi>(
  V1AccountApi,
  Config.shift.url,
  keepOnErrorAxios
)
