import { KeepOnErrorAccounts, Accounts } from '@/services/api/Accounts'

const NEW_EMAIL_HOTP_ID = 'newEmail.hotpId'
const NEW_EMAIL_EMAIL = 'newEmail.email'

export const EmailChange = {
  // Initiate verification of specified email
  async startVerifyEmailFlow(newEmail: string) {
    // Store identifier in session storage..
    const identifier = (await KeepOnErrorAccounts.api.requestUserEmailChange({
      email: newEmail
    })).data.data.hotpId
    window.sessionStorage.setItem(NEW_EMAIL_HOTP_ID, identifier)
    window.sessionStorage.setItem(NEW_EMAIL_EMAIL, newEmail)
    return identifier
  },
  // Finishes verification of specified email by
  // providing the user inputted otp code.
  async finishVerifyEmailFlow(code: string) {
    const identifier = window.sessionStorage.getItem(NEW_EMAIL_HOTP_ID)
    const email = window.sessionStorage.getItem(NEW_EMAIL_EMAIL)

    if (identifier == null || email == null) {
      throw new Error('Invalid changeEmailFlow state')
    }

    const requestStatus = (await Accounts.api.verifyUserEmail({
      email: email,
      hotp: code,
      hotpId: identifier
    })).data.data.status === 'success'

    if (requestStatus) {
      window.sessionStorage.removeItem(NEW_EMAIL_HOTP_ID)
      window.sessionStorage.removeItem(NEW_EMAIL_EMAIL)
    }

    return requestStatus
  }
}
