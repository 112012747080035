

























































import { Component, Vue } from 'vue-property-decorator'
import Success from '@/components/Success.vue'
import Otp from '@/components/facts/Otp.vue'
import BackButton from '@/components/layout/BackButton.vue'
import NextButton from '@/components/layout/NextButton.vue'
import constants from '@/constants'
import { Action, Getter } from 'vuex-class'
import { ACTION_SET_EMAIL, ACTION_SET_NEW_EMAIL } from '@/store/modules/user/actionTypes'
import { EmailChange } from '@/services/EmailChangeService'
import { RecaptchaSiteKeyService } from '@/services/RecaptchaSiteKeyService'
import { Shift } from '@/services/Shift'
import Recaptcha from '@/components/Recaptcha.vue'
import { TranslateResult } from 'vue-i18n'

@Component({
  components: {
    Recaptcha,
    Otp,
    Success,
    BackButton,
    NextButton
  }
})
export default class OTPAuth extends Vue {
  @Getter('user/newEmail') newEmail!: string
  @Action(ACTION_SET_EMAIL) setEmail!: (email: string) => void
  @Action(ACTION_SET_NEW_EMAIL) setNewEmail!: (email: string) => void

  private captchaEnabled = false
  private recaptchaToken = ''
  private submitDisabled = true
  private otpLength = 6
  private otp = ''
  private flashMessage: TranslateResult = ''
  private flashTimer: any
  private routes = constants.routeNames

  protected async mounted() {
    this.captchaEnabled = await RecaptchaSiteKeyService.getSiteKey() !== ''
  }

  async submitOtp() {
    this.hideFlash()

    if (this.captchaEnabled) {
      const ident = window.sessionStorage.getItem('newEmail.hotpId') || ''
      const result = await Shift.verifyRecaptchaToken(this.recaptchaToken, ident)

      if (!result.valid) {
        this.flash('applicationOtp.info.recaptchaFailed')
        return
      }
    }

    const verifySuccessful = await EmailChange.finishVerifyEmailFlow(this.otp)

    if (verifySuccessful) {
      this.setEmail(this.newEmail)
      this.setNewEmail('')

      await this.$router.push({ name: constants.routeNames.UPDATE_MY_INFO_SUCCESS })
    } else {
      this.otp = ''
      this.recaptchaToken = ''
      this.updateSubmitButtonStatus()

      if (this.captchaEnabled) {
        const recaptcha = this.$refs?.recaptcha as Recaptcha
        recaptcha.reset()
      }

      const next = this.$refs?.next as NextButton
      next.reset()

      this.$showErrorPopUp(this.$t('updateMyInfo.error.otpVerifyError').toString())
    }
  }

  verifyRecaptchaToken(recaptchaToken: string) {
    this.recaptchaToken = recaptchaToken

    this.updateSubmitButtonStatus()
  }

  backToEmail() {
    this.$router.push({ name: constants.routeNames.UPDATE_MY_INFO })
  }

  goToFaq() {
    this.$modal.show(constants.modalNames.FAQ_MODAL)
  }

  flash(msg: string) {
    this.flashMessage = this.$t(msg)
    this.flashTimer = setTimeout(() => {
      this.flashMessage = ''
    }, 30000) // Hide message after 30 seconds
  }

  hideFlash() {
    clearTimeout(this.flashTimer)
    this.flashMessage = ''
  }

  private input(otp: string) {
    this.otp = otp

    this.updateSubmitButtonStatus()
  }

  updateSubmitButtonStatus() {
    this.submitDisabled = (this.captchaEnabled && this.recaptchaToken.length === 0) || (this.otp?.length !== this.otpLength)
  }
}
